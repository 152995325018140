import { getAuthorization } from './authorization';
import { AuthService } from './authorization/auth.service';
import { BreadcrumbsManager } from './breadcrumbs-manager';
import { DockConfiguration, MicrofrontendConfiguration } from './configuration';
import { runPrePipeline } from './configuration/middleware';
import {
  getUtilityModuleDefinitions,
  processConfiguration,
} from './configuration/process-configuration';
import { AuthConfigurationSchema } from './configuration/schemas/auth';
import { DockContext } from './context';
import { ApplicationLinkConfiguration, LinksManager } from './links-manager';
import { NavigatorManager } from './navigation-manager';
import { ThemeManager } from './theme-manager';
import { DockSDK, JSONArray, JSONObject, SingleSpaEntry } from './types';
import { getNavigateTo, NavigateTo } from './utility/navigate-to';

let initializeFunction: (val?: unknown) => void;
const initialize = new Promise((resolve, reject) => {
  initializeFunction = resolve;
});

interface BootstrapConfig {
  url?: string;
  configuration?: JSONObject;
}

let linksManager: LinksManager;
const dockContext = new DockContext();
const breadcrumbsManager = new BreadcrumbsManager(dockContext);
const themeManager = new ThemeManager();
let userManager: AuthService;
const navigatorManager = new NavigatorManager();
let navigateTo: NavigateTo;

const fetchDockConfiguration = async (
  config: BootstrapConfig,
): Promise<JSONObject> => {
  let configurationJson: JSONObject;
  if (config.url) {
    configurationJson = await fetch(config.url).then(
      (response) => response.json() as Promise<JSONObject>,
    );
  }
  if (config.configuration) {
    configurationJson = config.configuration;
  }
  return configurationJson!;
};

let dockConfiguration: DockConfiguration;

export const getDockConfigurationData = () => dockConfiguration;

export const getLinksManager = () => linksManager;
export const getContext = () => dockContext;
export const getBreadcrumbManager = () => breadcrumbsManager;
export const getThemeManager = () => themeManager;
export const getNavigatorManager = () => navigatorManager;

export const bootstrap = async (
  config: BootstrapConfig = { url: '/dock-config.json' },
  extend: JSONObject = {},
): Promise<DockConfiguration> => {
  // fetch dock configuration
  let unprocessedDockConfiguration = await fetchDockConfiguration(config);
  unprocessedDockConfiguration = await runPrePipeline(
    unprocessedDockConfiguration,
  );
  // get auth
  const { authConfiguration } = unprocessedDockConfiguration;

  userManager = await getAuthorization(
    AuthConfigurationSchema.parse(authConfiguration),
  );
  const utilityModules = getUtilityModuleDefinitions(
    (unprocessedDockConfiguration.microfrontends as JSONArray) || [],
  );
  addMicrofrontendsToImportMaps(utilityModules);
  // process dock configuration by fetching microfrontends with accessToken
  dockConfiguration = await processConfiguration(
    { ...unprocessedDockConfiguration, ...extend },
    userManager.accessToken,
  );
  addMicrofrontendsToImportMaps(dockConfiguration.microfrontends);
  linksManager = new LinksManager(dockConfiguration);
  prepareBreadcrumbs(dockConfiguration.microfrontends);
  if (dockConfiguration.navigatorConfiguration?.links) {
    const defaultPinnedApps = dockConfiguration.navigatorConfiguration?.links;
    navigatorManager.setNavigatorLinks(defaultPinnedApps);
  }
  initializeFunction();
  navigateTo = getNavigateTo(dockConfiguration.legacySSR);
  return dockConfiguration;
};

const addMicrofrontendsToImportMaps = (
  microfrontends: DockConfiguration['microfrontends'],
) => {
  const importMaps: Record<string, string> = microfrontends.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: currentValue.url,
    }),
    {},
  );
  return System.addImportMap({ imports: importMaps });
};

const prepareBreadcrumbs = (
  microfrontends: DockConfiguration['microfrontends'],
) => {
  const breadcrumbManager = getBreadcrumbManager();
  microfrontends.forEach((mfe) => {
    if (mfe.type === 'application') {
      const linkConfiguration: ApplicationLinkConfiguration = {
        appId: mfe.id,
        basePath: mfe.path,
      };
      breadcrumbManager.registerBreadcrumb(mfe.id, linkConfiguration);
    }
  });
};

export const getSingleSpaMicrofrontend = async (
  id: string,
): Promise<SingleSpaEntry> => {
  await initialize;

  try {
    return await System.import(id);
  } catch (error) {
    console.error(`Failed to load microfrontend ${id}:`, error);
    throw error;
  }
};

export const getUtilityModule = async (id: string) => {
  await initialize;
  return System.import(id);
};

export const getUserManager = async () => {
  await initialize;
  return userManager;
};

export const getSDK = async (
  mfeConfig: MicrofrontendConfiguration,
): Promise<DockSDK> => {
  return {
    getToken: () => userManager.accessToken,
    userInformation: () => userManager.userInfo,
    userManager: () => userManager,
    themeManager: () => themeManager,
    linksManager: () => linksManager,
    context: () => dockContext,
    breadcrumbManager: () => breadcrumbsManager,
    navigatorManager: () => navigatorManager,
    navigateTo: navigateTo,
    getMicrofrontend: getSingleSpaMicrofrontend,
  };
};

export { BREADCRUMBS_UPDATE_EVENT } from './breadcrumbs-manager';

export type {
  DockConfiguration,
  MicrofrontendConfiguration,
  NavigatorConfiguration,
  FooterConfiguration,
  MountableMicrofrontendConfiguration,
} from './configuration';
export type { BreadcrumbNode } from './breadcrumbs-manager';
export type { AuthService } from './authorization/auth.service';
export type * from './context/types';

export * from './types';
