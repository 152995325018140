import { JSONObject, MiddlewareFunc } from '../../types';
import { EnvSchema } from '../schemas/env';
import { transformData } from './utils';

type ObjectMap = { [key: string]: string };

export const templateString = <T>(val: T, vars: ObjectMap): T => {
  if (typeof val === 'string') {
    const pattern = /{([^}]+)}/g;
    const result = val.replace(pattern, (match, key: string) => {
      return vars[key] ?? match;
    });
    return result as unknown as T;
  }
  return val;
};

export const templateMiddleware: MiddlewareFunc = async (
  entry: JSONObject & { env?: ObjectMap },
) => {
  // we do must not process environment variables
  const { env, ...config } = entry;
  EnvSchema.optional().parse(entry.env);
  const envVariables = {
    ...(env || {}),
    ...{
      host: location.host,
      origin: location.origin,
      hostname: location.hostname,
    },
  };
  // we know that entry is JSONObject
  const processedConfig = transformData(config, (val) =>
    templateString(val, envVariables),
  ) as JSONObject;
  return {
    ...processedConfig,
    env,
  };
};
