import { JSONObject, MiddlewareFunc } from '../../types';
import { DockConfiguration, MiddlewareConfiguration } from '../index';
import { FetchableMiddleware } from './fetchable';
import { templateMiddleware } from './template';
import { ValidateMiddleware } from './validate';

const WrapperMiddleware: (
  middlewareConfig: MiddlewareConfiguration,
) => MiddlewareFunc = (middlewareConfig) => {
  return async (config, options) => {
    const { middleware }: { middleware: MiddlewareFunc } = await System.import(
      middlewareConfig.id,
    );
    return middleware(config, { ...middlewareConfig.config, ...options });
  };
};

export const runPrePipeline = async (config: JSONObject) => {
  return await templateMiddleware(config, { token: '' });
};

export const runPipeline = async (
  middleware: DockConfiguration['middleware'],
  config: JSONObject,
  token: string,
) => {
  const pipeline = [
    FetchableMiddleware,
    ...(middleware?.map(WrapperMiddleware) || []),
  ];
  let accumulator = config;
  for (const middleware of pipeline) {
    try {
      accumulator = await middleware(accumulator, { token });
    } catch (e) {
      console.log(e);
    }
  }
  try {
    accumulator = await templateMiddleware(accumulator, { token });
  } catch (e) {
    console.log(e);
  }

  return await ValidateMiddleware(accumulator);
};
