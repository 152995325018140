import { z } from 'zod';

import { AuthConfigurationSchema } from './schemas/auth';
import { EnvSchema } from './schemas/env';
import { FooterConfigurationSchema } from './schemas/footer';
import {
  ApplicationMicrofrontendSchema,
  MicrofrontendConfigurationSchema,
} from './schemas/microfrontends';
import { MiddlewareConfigurationSchema } from './schemas/middleware';
import {
  NavigatorApplicationLinkSchema,
  NavigatorConfigurationSchema,
} from './schemas/navigator';

export const DockConfigurationSchema = z.object({
  env: EnvSchema.optional(),
  loadingStyle: z.string().optional(),
  legacySSR: z.boolean().optional(),
  middleware: z.array(MiddlewareConfigurationSchema).optional(),
  microfrontends: z.array(MicrofrontendConfigurationSchema),
  authConfiguration: AuthConfigurationSchema,
  navigatorConfiguration: NavigatorConfigurationSchema.optional(),
  footerConfiguration: FooterConfigurationSchema.optional(),
});

export type DockConfiguration = z.infer<typeof DockConfigurationSchema>;
export type MiddlewareConfiguration = z.infer<
  typeof MiddlewareConfigurationSchema
>;
export type ApplicationMicrofrontend = z.infer<
  typeof ApplicationMicrofrontendSchema
>;

export type MicrofrontendConfiguration = z.infer<
  typeof MicrofrontendConfigurationSchema
>;
export type MountableMicrofrontendConfiguration = Exclude<
  MicrofrontendConfiguration,
  { type: 'utility' }
>;

export type NavigatorConfiguration = z.infer<
  typeof NavigatorConfigurationSchema
>;

export type NavigatorApplication = z.infer<
  typeof NavigatorApplicationLinkSchema
>;
export type AuthConfiguration = z.infer<typeof AuthConfigurationSchema>;

export type FooterConfiguration = z.infer<typeof FooterConfigurationSchema>;
